
*, *::before, *::after {
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: 'Fredoka One', cursive;
  font-weight: 400;
  color: white;
}

body {
  background-color: black;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.content {
  --positionX: 7;
  --positionY: 7;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;

  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 8vw;
}

h2 {
  font-size: 4vw;
}

@media screen and (min-width: 500px) {
  h1 {
    font-size: 38px !important;
  }

  h2 {
    font-size: 18px !important;
  }
}
@keyframes slideDownAdditionalText {
  0% {
    height: 1px;
  }
  100% {
    height: 100px;
  }
}

span {
  animation: 0.2333s cubic-bezier(0.54, 0.93, 1, 1.07) 0s 1 slideDownAdditionalText;
  animation-fill-mode: forwards;
}

.mobileAnimation {
  user-select: none;
  font-family: 'Fredoka One', cursive;
  position: absolute;
  top: 45%;
  left: 50%;
  animation: color 3s infinite linear;
  text-shadow: 0 0 10px #000a;
  transition: all 0.5s;
  @for $i from 0 to 5 {
    &:nth-child(#{$i + 1}) {
      font-size: #{120 + $i * 6}px;
      animation-delay: #{$i * -0.4}s;
      opacity: #{0.5 + $i * 0.1};
      transform: translateX(calc(-50% - (var(--positionX) - 7) * #{(7 - $i) * 3px})) translateY(calc(-50% - (var(--positionY) - 7) * #{(7 - $i) * 3px})) rotateX(calc(0deg - (var(--positionY) - 7) * 5deg)) rotateY(calc((var(--positionX) - 7) * 5deg));
    }
  }

  @keyframes color {
    @for $c from 0 through 5 {
      #{$c * 10%} {
        color: hsl(36 * $c, 75%, 75%);
      }
    }
  }
}

.desktopAnimation {
  user-select: none;
  font-family: 'Fredoka One', cursive;
  position: absolute;
  top: 45%;
  left: 50%;
  animation: color 3s infinite linear;
  text-shadow: 0 0 10px #000a;
  transition: all 0.5s;
  @for $i from 0 to 10 {
    &:nth-child(#{$i + 1}) {
      font-size: #{50 + $i * 10}px;
      animation-delay: #{$i * -0.3}s;
      opacity: #{0.1 + $i * 0.1};
      transform: translateX(calc(-50% - (var(--positionX) - 7) * #{(7 - $i) * 3px})) translateY(calc(-50% - (var(--positionY) - 7) * #{(7 - $i) * 3px})) rotateX(calc(0deg - (var(--positionY) - 7) * 5deg)) rotateY(calc((var(--positionX) - 7) * 5deg));
    }
  }

  @keyframes color {
    @for $c from 0 through 10 {
      #{$c * 10%} {
        color: hsl(36 * $c, 75%, 75%);
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .desktopAnimation {
    @for $i from 0 to 10 {
      &:nth-child(#{$i + 1}) {
        font-size: #{25 + $i}vw;
      }
    }
  }

  .mobileAnimation {
    @for $i from 0 to 5 {
      &:nth-child(#{$i + 1}) {
        font-size: #{30 + $i}vw;
      }
    }
  }
}

